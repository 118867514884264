module.exports={
    "Development": false,
    "PdfLink": true,
    "Domain": "https://app.preprod.mycashmachine.fr",
    "AppTheme": "green",
    "Language": "FR",
    "ApiConfig": {
        "domain": "https://api.preprod.mycashmachine.fr/applications/",
        "version": "APPWEB10"
    },
    "Node": {
        "port": 4004
    },
    "Messages": {
        "timeout": 60000
    },
    "ApplicationSecret": "94e6bf462175154282b7eb8c262fb6c3",
    "MinutesBeforeAutoLogout": 180
}
